<template>
  <main class="project-page page">
    <BreadcrumbsComponent :title="project?.title" :links="links" />
    <div class="container-padding">
      <div v-if="project" class="project-page__inner">
        <ProjectAsideComponent />
        <div class="project-page__body">
          <div v-if="project.target" class="project-page__wrapper">
            <span class="project-page__title">Цель:</span>
            <span class="project-page__subtitle">{{ project.target }}</span>
          </div>
          <div v-if="project.result" class="project-page__wrapper">
            <span class="project-page__title">Результат:</span>
            <span class="project-page__subtitle">{{ project.result }}</span>
          </div>
          <div v-if="project.description" class="project-page__wrapper">
            <span class="project-page__title">Описание:</span>
            <EditorJSComponent
              v-if="$options.filters.editor_has_text(project.description)"
              :text="project.description"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import PROJECTS_OPEN_PAGE from "gql/pages/ProjectsOpenPage.graphql";
import ProjectAsideComponent from "./components/ProjectAsideComponent.vue";

export default {
  name: "ProjectPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: PROJECTS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.dispatch("projects/save", data);
      })
      .catch(() => {});
  },
  computed: {
    project() {
      return this.$store.state.projects.projects_item;
    },
    links() {
      return [
        {
          title: "Проектный офис",
          to: { name: "projects" },
        },
      ];
    },
  },
  components: {
    ProjectAsideComponent,
    EditorJSComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.project-page {
  padding 15px 0 60px 0
  background var(--main-1-gray-light)

  &__inner {
    display grid
    grid-gap 40px
    grid-template-columns 320px 1fr

    +below(870px) {
      display flex
      flex-direction column
    }
  }

  &__body {
    display flex
    flex-direction column
    gap 30px
  }

  &__wrapper {
    display flex
    flex-direction column
    gap 5px
  }

  &__title {
    font-size 1.125rem
    font-weight 600
  }
}
</style>
