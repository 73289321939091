<template>
  <aside v-if="project" class="project-aside">
    <div class="project-aside__img">
      <ImgComponent :img="project.img" />
    </div>
    <div v-if="project.publication_date" class="project-aside__info">
      <span class="project-aside__title">Дата публикации:</span>
      <span class="project-aside__description">{{ project.publication_date | formatDate }}</span>
    </div>
    <!--    <div class="project-aside__info-svg-wrapper">-->
    <!--      <div class="project-aside__info-svg" v-for="item in 4" :key="item">-->
    <!--        <IconComponent name="chat" />-->
    <!--      </div>-->
    <!--    </div>-->
  </aside>
</template>

<script>
// import IconComponent from "components/IconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "ProjectAsideComponent",
  computed: {
    project() {
      return this.$store.state.projects.projects_item;
    },
  },
  components: {
    // IconComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.project-aside {
  display flex
  flex-direction column

  &__info {
    display inline-flex
    padding 15px
    gap 5px
    background var(--white)
    border-top 1px solid var(--main-1-gray-light)
  }

  &__title {
    font-size: 0.875rem
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }

  &__description {
    font-size: 0.875rem
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__svg-wrapper {
    border-top 1px solid var(--main-1-gray-light)
    display grid
    grid-template-columns repeat(4, 1fr)
    background var(--white)
    padding 15px
  }

  &__svg {
    border 1px solid var(--gray-dark)
    border-left none
    height: 50px;
    display flex
    justify-content center
    align-items center

    &:nth-child(1) {
      border-left 1px solid var(--gray-dark)
    }

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  &__img {
    width 100%

    img {
      height 227px
      object-fit cover
    }
  }
}
</style>
